<template>
  <a-config-provider :locale="local">
    <div class="order-wrap">
      <div class="order-title">押金冲抵</div>
      <div class="order-main">
        <div class="order-form">
          <a-form :form="searchForm" layout="inline">
            <a-form-item
              class="flight-city-form-item"
              label="航段"
              label-align="left"
            >
              <FlightCityGroupSelector
                ref="citySelector"
                :depCity="cities.depCity"
                :arrCity="cities.arrCity"
                @depChange="onDepChange"
                @arrChange="onArrChange"
              />
            </a-form-item>
            <a-form-item label="审批日期">
              <a-range-picker
                size="small"
                v-decorator="['searchForm.rangeTime']"
                show-time
                format="YYYY-MM-DD"
              />
            </a-form-item>
            <a-form-item label="退款审批状态">
              <a-select
                size="small"
                style="width: 150px"
                v-decorator="[
                  'searchForm.refundStatus',
                  {
                    initialValue: '',
                  },
                ]"
              >
                <a-select-option
                v-for="(status) in depositBalanceStatus"
                :key="status.value"
                :value="status.value">{{ status.label }}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item>
              <a-button size="small" type="primary" @click="onSeach"> 查询 </a-button>
              <a-button size="small" type="primary" ghost @click="onReset"> 重置 </a-button>
            </a-form-item>
          </a-form>
        </div>
        <div class="order-form">
          <a-form :form="balanceForm" layout="inline">
            <a-form-item label="输入冲抵的订单号">
              <a-input
                size="small"
                v-decorator="['balanceForm.orderNo', { rules: [{ required: true, message: '请输入要冲抵的订单' }] }]"
              />
            </a-form-item>
            <a-form-item>
              <a-button size="small" type="primary" @click="onBalance" :loading="submitLoading">冲抵</a-button>
            </a-form-item>
          </a-form>
        </div>
        <div class="order-table">
          <a-table
            :scroll="{x: 'max-content'}"
            ref="orderTable"
            :columns="columns"
            :data-source="tableData"
            :pagination="pageOptions"
            :rowSelection="rowSelection"
            :rowKey="
              (record, index) => {
                return index;
              }
            "
            :loading="loading"
            bordered
          >
          <template slot="index" slot-scope="index">
              {{ index  }}
            </template>
            <template slot="orderNo" slot-scope="orderNo">
              <router-link
                :to="{
                  path: '/orderManager/orderDetail',
                  query: { id: orderNo },
                }"
                >{{ orderNo }}</router-link
              >
            </template>
            <template slot="segments" slot-scope="segments">
              <div v-for="(item, idx) in segments" :key="idx">
                {{ item.orgCity }}-{{ item.dstCity }}
              </div>
            </template>
            <template slot="tradeNo" slot-scope="tradeNo, row">
              <div>{{ row.payment.tradeNo }}</div>
            </template>
            <template slot="payStatus" slot-scope="payStatus">
              <div>{{ payStatus | payStatus }}</div>
            </template>
            <template slot="payType" slot-scope="payType">
              <div>{{ payType | bank }}</div>
            </template>
            <template slot="refundStatus" slot-scope="refundStatus">
              <div>{{ refundStatus | refundStatus }}</div>
            </template>
          </a-table>
        </div>
      </div>
    </div>
  </a-config-provider>
</template>

<script>
import tableBaseMixin from '@/common/mixin/table-base-mixin'
import orderSearchMixin from '@/common/mixin/order-search-mixin'

import { format } from '@/filters/time'
import { status as payStatus, bank } from '@/filters/pay'
import { refundStatus } from '@/filters/order'

// import { airCity } from '@/filters/flight'

import FlightCityGroupSelector from '@/components/FightCityGroupSelector/FlightCityGroupSelector'

import { fetchDepositOffsetList, depositOffset } from '@/api/order'

const depositBalanceStatus = [
  { label: '全部', value: '' },
  { label: '已审批', value: 'SPASS' },
  { label: '已冲抵', value: 'OFFSET' }
]

const columns = [
  {
    title: '序号',
    dataIndex: 'index',
    scopedSlots: { customRender: 'index' },
    align: 'center'
  },
  {
    title: '订单号',
    dataIndex: 'orderNo',
    scopedSlots: { customRender: 'orderNo' }
  },
  {
    title: '申退单号',
    dataIndex: 'refundNo'
  },
  {
    title: '订单航段',
    dataIndex: 'segments',
    scopedSlots: { customRender: 'segments' }
  },
  {
    title: '支付方式',
    dataIndex: 'payType',
    scopedSlots: { customRender: 'payType' }
  },
  {
    title: '支付流水',
    dataIndex: 'tradeNo',
    scopedSlots: { customRender: 'tradeNo' }
  },
  {
    title: '支付金额',
    dataIndex: 'payAmount'
  },
  {
    title: '支付日期',
    dataIndex: 'payDate'
  },
  {
    title: '支付状态',
    dataIndex: 'payStatus',
    scopedSlots: { customRender: 'payStatus' }
  },
  {
    title: '审核退款金额',
    dataIndex: 'repayAmount'
  },
  {
    title: '退款/冲抵状态',
    dataIndex: 'refundStatus',
    scopedSlots: { customRender: 'refundStatus' }
  }
]

function dataParse (content, params) {
  const { page, size } = params

  return content.map((col, index) => {
    const segments = col.segmentInfos.map((seg) => {
      const { depCode, arrCode } = seg
      return { orgCity: depCode, dstCity: arrCode }
    })

    return {
      key: col.orderNo,
      index: page * size + (index + 1),
      ...col,
      ...{
        segments: segments,
        orderStatus: col.orderStatus,
        payType: col.payment.payType,
        paySerious: col.payment.paymentNo,
        payAmount: col.payment.amount,
        payDate: col.payment.createTime,
        payStatus: col.payment.payStatus,
        status: col.refundStatus
      }
    }
  })
}

export default {
  name: 'depositBalanceSearch',
  components: {
    FlightCityGroupSelector
  },
  mixins: [
    tableBaseMixin,
    orderSearchMixin
  ],
  data () {
    return {
      searchForm: this.$form.createForm(this, { name: 'search_form' }),
      balanceForm: this.$form.createForm(this, { name: 'balance_form' }),
      columns,
      cities: {
        depCity: '',
        arrCity: ''
      },
      params: {
        arrCode: '',
        auditFinishTimeEnd: '',
        auditFinishTimeStart: '',
        refundStatus: '',
        depCode: '',
        page: 0,
        size: 10
      },
      depositBalanceStatus,
      selectedRowKeys: [],
      selectedRow: null,
      submitLoading: false,
      loading: false
    }
  },
  computed: {
    rowSelection () {
      return {
        type: 'checkbox',
        onSelect: this.rowSelectHandle,
        onSelectAll: this.rowSelectAllHandle,
        selectedRowKeys: this.selectedRowKeys,
        getCheckboxProps: record => ({
          props: {
            disabled: record.refundStatus === 'OFFSET'
          }
        })
      }
    }
  },
  mounted () {
    this.fetchTableData()
  },
  methods: {
    async fetchTableData () {
      this.loading = true
      try {
        const result = await fetchDepositOffsetList(this.params)
        this.loading = false

        const { content, size, totalElements, number } = result
        this.tableData = dataParse(content, this.params)

        this.pageOptions.total = totalElements
        this.pageOptions.current = number + 1
        this.pageOptions.pageSize = size
      } catch (e) {
        this.loading = false
        this.$message.error(e.message)
      }
    },
    onSeach () {
      const fields = this.searchForm.getFieldsValue().searchForm
      const { cities } = this

      // console.log(fields)

      const params = {
        depCode: cities.depCity,
        arrCode: cities.arrCity,
        refundStatus: fields.refundStatus
      }

      if (fields.rangeTime) {
        params.auditFinishTimeStart = fields.rangeTime[0] ? fields.rangeTime[0].format('YYYY-MM-DD 00:00:00') : ''
        params.auditFinishTimeEnd = fields.rangeTime[1] ? fields.rangeTime[1].format('YYYY-MM-DD 23:59:59') : ''
      }

      this.params = {
        ...this.params,
        ...params
      }

      this.fetchTableData()
    },
    onBalance () {
      if (!this.selectedRow || !this.selectedRow.length) {
        this.$message.warn('请先选择要冲抵的订单')
        return
      }

      this.balanceForm.validateFields((err, values) => {
        if (!err) {
          this.submitBalance(values)
        }
      })
    },
    async submitBalance (values) {
      this.submitLoading = true
      try {
        const params = {
          action: 'TEAM_DEPOSIT',
          refundNos: this.selectedRow.map((item) => { return item.refundNo }),
          orderNo: values.balanceForm.orderNo
        }
        await depositOffset(params)
        this.$info({
          content: '冲抵成功'
        })
        this.fetchTableData()
        this.selectedRow = []
        this.selectedRowKeys = []
        this.submitLoading = false
      } catch (e) {
        this.submitLoading = false
        this.$message.error(e.message)
      }
    },
    onTableChange (pagination) {
      this.params.page = pagination.current - 1
      this.params.size = pagination.pageSize

      this.fetchTableData()
    },
    onReset () {
      this.$refs.citySelector.reset()
      this.searchForm.resetFields()
    },
    onDepChange (city) {
      this.cities.depCity = city
    },
    onArrChange (city) {
      this.cities.arrCity = city
    },
    rowSelectHandle (selected, record, selectedRows, nativeEvent) {
      this.selectedRow = selectedRows
      this.selectedRowKeys = selectedRows.map((item) => { return item.index - 1 })
    },
    rowSelectAllHandle (selected, record, selectedRows, nativeEvent) {
      if (selected) {
        this.selectedRow = selectedRows
        this.selectedRowKeys = selectedRows.map((item) => { return item.index - 1 })
      } else {
        this.selectedRow = []
        this.selectedRowKeys = []
      }
    }
  },
  filters: {
    payStatus,
    formatTime: format,
    bank,
    refundStatus
  }
}
</script>

<style lang="less" scoped>
.flight-city-form-item {
  width: 300px;
}
</style>
